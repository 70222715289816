import { WEB_ANALYTICS_PPV_REPORTS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Reports View", "search-by": "ReportName,ReportDescription", "ph-search-by": "Search by ReportName, ReportDescription", "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("View Report")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onClickViewReport(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "ReportName", attrs: { "data-index": "reportName", "title": "Name", "width": 265, "sorter": true } }), _c("text-field", { key: "ReportDescription", attrs: { "data-index": "reportDescription", "title": "Description" } }), _c("text-field", { key: "ReportUrl", attrs: { "data-index": "reportUrl", "title": "Url" } })], 1);
};
var staticRenderFns$1 = [];
var PpvActualReports_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "PpvActualReports",
  inject: ["crud"],
  methods: {
    onClickViewReport(record) {
      window.open(`${record.navigateUrl}`, "_blank");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "25a1940e", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var PpvActualReports = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-reports" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.ppv.reports", "api-url": _vm.apiUrl, "resource-id-name": "reportId", "page": _vm.page } }, [_c("ppv-actual-reports")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    PpvActualReports
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPV_REPORTS,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "reports",
          title: "Reports",
          path: "/web-analytics/ppv-actual-reports"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
